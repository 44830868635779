.intro-animation {
  .intro-overlay {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 8;
    background: #1a1a1a;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
