@import "../fonts/SuisseIntl-Book.ttf";
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./hero.scss";
@import "./introAnimation.scss";
@import "./backgroundLogo.scss";
@import "./about.scss";
@import "./MyWork01.scss";
@import "./MyWork02.scss";
@import "./MyWork03.scss";
@import "./customCursor.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "SuisseIntl-Book";
  src: local("SuisseIntl-Book"),
    url("../fonts/SuisseIntl-Book.otf") format("opentype");
  //font-weight: book;
  //-webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Graphik-Light";
  src: local("Graphik-Light"),
    url("../fonts/Graphik-Light.ttf") format("truetype");
  font-weight: light;
}
@font-face {
  font-family: "Graphik-Regular";
  src: local("Graphik-Regular"),
    url("../fonts/Graphik-Regular.ttf") format("truetype");
}

body,
html {
  visibility: hidden;
  font-family: "SuisseIntl-Book";
  //font-weight: book;
  //-webkit-font-smoothing: antialiased;
  color: $black;
  background-color: $white;
}

.is-loading {
  overflow-y: hidden;
  height: 100vh;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}
