@keyframes mouseintro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.main-cursor,
.secondary-cursor {
  z-index: 999;

  pointer-events: none;
  position: fixed;
  transform: trasnform3d(0, 0, 0);
  opacity: 0;
  animation: mouseintro 1s ease-in 10.5s forwards;
  @media (max-width: 768px) {
    visibility: hidden;
  }
}

.main-cursor {
  //transition: opacity 1s cubic-bezier(0.77, 0, 0.175, 1);
  //animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;

  .main-cursor-background {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

.secondary-cursor {
  width: 80px;
  height: 80px;

  .cursor-background {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #e3154d;
    position: relative;
  }
}

.view {
  .cursor-background {
    animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  }
  .main-cursor-background {
    animation: fadeIn 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    &:after {
      content: "View";
      font-size: 15px;
      letter-spacing: 0.7px;
      color: white;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.8);
  }
}

.contact {
  .main-cursor-background {
    animation: fadeIn 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  }
  .cursor-background {
    animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  }
}

.accordion {
  .cursor-background {
    animation: dissapear 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  }
}
@keyframes dissapear {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
