.main {
  height: 100vh;
  .container {
    width: auto;
    min-width: 960px;
    margin: 0 auto;
    //padding: 0 32px;
    height: 100%;
    @media (min-width: 1024px) {
      max-width: 960px;
    }
    @media (min-width: 1216px) {
      max-width: 1152px;
    }
    @media (min-width: 1408px) {
      max-width: 1244px;
    }
    @media (max-width: 961px) {
      min-width: auto;
    }

    .row {
      flex-direction: column;
      justify-content: center;
      @include media("<=phone") {
        padding: 0 32px;
      }
      .animatecolor {
        font-size: 4rem;
        line-height: 4.4rem;
        z-index: 9;
        color: #dfdfdf;
        mix-blend-mode: difference;
        //letter-spacing: 0.02rem;
        @media (max-width: 580px) {
          font-size: 3rem;
          line-height: 2.8rem;
        }
        @include media("<=phone") {
          font-size: 2.2rem;
          line-height: 2.1rem;
          -webkit-text-stroke: 1px;
          letter-spacing: 0.01rem;
        }
        .line {
          margin-bottom: 8px;
          height: 4.3rem;
          position: relative;
          overflow: hidden;
          font-weight: bold;
          @media (max-width: 580px) {
            height: 48px;
          }
          @include media("<=phone") {
            height: 2.5rem;
          }
          span {
            position: absolute;
          }
        }
      }
      .hero-p-row {
        max-width: 440px;
        position: relative;
        z-index: 2;
        padding-top: 1.2rem;
        @include media("<=tablet") {
          width: 375px;
        }
        @include media("<=phone") {
          width: 315px;
        }
        p {
          font-size: 1rem;
          color: $black;
          display: flex;
          font-family: "Graphik-Regular";
          line-height: 1.9rem;
          color: #544f47;
          @include media("<=tablet") {
            font-size: 1rem;
          }
          @include media("<=phone") {
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
  }
}
