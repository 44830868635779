.sectionV2 {
  /*visibility: hidden;*/
  z-index: 2;
  margin-bottom: 320px;
  @include media("<=tablet") {
    margin-bottom: 10rem;
  }
  @include media("<=phone") {
    margin-bottom: 12rem;
  }

  .containerAboutV2 {
    width: auto;
    //max-width: 960px;
    margin: 0 auto;
    //padding: 0 32px;
    height: 100%;
    @media (min-width: 1024px) {
      max-width: 960px;
    }
    @media (min-width: 1216px) {
      max-width: 1152px;
    }
    @media (min-width: 1408px) {
      max-width: 1244px;
    }
    .aboutTwo-innerV2 {
      padding: 0 32px;
      .aboutTwo-contentV2 {
        .aboutTwo-content-innerV2 {
          //width: 400px;
          color: $black;
          h4 {
            font-size: 56px;
            font-weight: 600;
            margin-bottom: 24px;
            @media (max-width: 580px) {
              font-size: 2rem;
              //ine-height: 2rem;
            }
            @include media("<=phone") {
              font-size: 1.9rem;
              //test
              -webkit-text-stroke: 0.5px;
              letter-spacing: 0.01rem;
              //line-height: 2.2rem;
            }
            .aboutTwo-content-lineV2 {
              margin: 0;
              height: 67px;
              overflow: hidden;
              @media (max-width: 580px) {
                height: 2.4rem;
              }
              @include media("<=phone") {
                height: 2.2rem;
              }
            }
          }
        }
        .aboutTwo-paragraph-innerV2 {
          right: 0;
          overflow: hidden;
          padding-left: 50%;
          @media (max-width: 873px) {
            padding-left: 0;
          }
        }

        p {
          font-size: 1rem;
          font-family: "Graphik-Regular";
          line-height: 1.9rem;
          padding-right: 48px;
          margin-bottom: 56px;
          max-width: 440px;
          color: #544f47;
          @include media("<=phone") {
            font-size: 14px;
            line-height: 2;
          }
        }
      }
    }
  }
  .home-section {
    margin-bottom: 40px;
    .container {
      width: auto;
      //min-width: 960px;
      margin: 0 auto;
      padding: 0 32px;
      height: 100%;
      @media (min-width: 1024px) {
        max-width: 960px;
      }
      @media (min-width: 1216px) {
        max-width: 1152px;
      }
      @media (min-width: 1408px) {
        max-width: 1244px;
      }
      .scroll-text {
        white-space: nowrap;
        font-size: 7rem;
        margin-top: 5rem;
        margin-bottom: 5rem;
        width: 100%;
        overflow: hidden;
        @media (max-width: 580px) {
          font-size: 4.4rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        @include media("<=phone") {
          font-size: 3rem;
          margin-top: 0.8rem;
          margin-bottom: 0.8rem;
        }
        .box {
          display: inline-block;
          padding-left: 10px;
          text-align: center;
        }
        .boxes {
          position: relative;
          left: 100vw;
          -webkit-text-stroke: 1.5px #424243;
          @include media("<=phone") {
            -webkit-text-stroke: 0.5px #424243;
          }
          color: rgba($color: #424243, $alpha: 0);
          span {
            color: $black;
          }
        }
      }
    }
  }
}
