.sectionV3 {
  /*visibility: hidden;*/
  z-index: 2;
  margin-bottom: 320px;
  @include media("<=tablet") {
    margin-bottom: 8rem;
  }
  @include media("<=phone") {
    margin-bottom: 12rem;
  }
  .containerAboutV3 {
    width: auto;
    //min-width: 960px;
    margin: 0 auto;
    height: 100%;
    @media (min-width: 1024px) {
      max-width: 960px;
    }
    @media (min-width: 1216px) {
      max-width: 1152px;
    }
    @media (min-width: 1408px) {
      max-width: 1244px;
    }
    .aboutTwo-innerV3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 873px) {
        flex-direction: column-reverse;
      }
      .aboutTwo-contentV3 {
        //width: 50%;
        padding: 0 32px;
        .aboutTwo-content-innerV3 {
          width: 400px;
          color: $black;
          @media (max-width: 873px) {
            width: 100%;
          }
          h4 {
            font-size: 56px;
            font-weight: 600;
            margin-bottom: 24px;
            @media (max-width: 580px) {
              font-size: 2rem;
            }
            @include media("<=phone") {
              font-size: 1.9rem;
              //test
              -webkit-text-stroke: 0.5px;
              letter-spacing: 0.01rem;
            }
            .aboutTwo-content-lineV3 {
              margin: 0;
              height: 67px;
              overflow: hidden;
              @media (max-width: 580px) {
                height: 2.4rem;
              }
              @include media("<=phone") {
                height: 2.4rem;
              }
            }
          }
          p {
            font-size: 1rem;
            font-family: "Graphik-Regular";
            line-height: 1.9rem;
            padding-right: 48px;
            margin-bottom: 56px;
            color: #544f47;
            @include media("<=phone") {
              font-size: 14px;
              line-height: 2;
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
    .section-images {
      width: 50%;
      position: relative;
      padding: 0 32px;
      /*@include media("<=tablet") {
        width: 100%;
        padding: 0;
      }*/
      @media (max-width: 873px) {
        width: 100%;
      }
      @media (max-width: 614px) {
        padding: 0;
      }
      .section-images-inner {
        .img-container {
          width: 400px;
          height: 400px;
          position: relative;
          overflow: hidden;
          visibility: hidden;
          display: flex;
          align-items: center;
          @media (max-width: 873px) {
            width: 100%;
            height: 600px;
          }
          @media (max-width: 658px) {
            height: 550px;
          }
          @media (max-width: 614px) {
            width: 100vw;
            height: 400px;
          }
          .stophover {
            position: absolute;
            left: 0;
            top: 0;
            background: transparent;
            width: 100%;
            height: 100%;
            z-index: 2;
          }
          .blue {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $white;
            overflow: hidden;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          img {
            width: 400px;
            position: absolute;
            @media (max-width: 873px) {
              width: 100%;
            }
            @media (max-width: 614px) {
              width: 100%;
            }
          }
        }
        .viewproject {
          position: relative;
          height: 0.7rem;
          top: 0;
          text-decoration: none;
          margin: 0.85rem 0 2rem;
          line-height: 1.3;
          font-size: 0.67rem;
          color: $black;
          //font-weight: bold;
          font-family: "SuisseIntl-Book";
          letter-spacing: 0.03rem;
          width: 5.4rem;
          overflow: hidden;
          @media (max-width: 614px) {
            left: 32px;
          }
          .revealtext {
            background: $white;
            height: 0.7rem;
            width: 250px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .tile_content {
        position: absolute;
        bottom: 3.6rem;
        left: 0;
        width: 100%;

        font-size: 1.4rem;

        transition: color 0.3s;
      }
      .tile_title {
        margin-left: -10%;

        white-space: nowrap;
      }
    }
  }
}
