.backgroundLogo {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.05;
  transition: opacity 1s ease;
  img {
    width: 400px;
    height: 400px;
  }
}
